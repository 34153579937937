// maubot - A plugin-based Matrix bot system.
// Copyright (C) 2019 Tulir Asokan
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as published by
// the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

> div.started-container {
    display: inline-flex;

    > span.started {
        display: inline-block;
        height: 0;
        width: 0;
        border-radius: 50%;
        margin: .5rem;

        &.true {
            &.sync_ok {
                background-color: $primary;
                box-shadow: 0 0 .75rem .75rem $primary; }

            &.sync_error {
                background-color: $warning;
                box-shadow: 0 0 .75rem .75rem $warning; } }

        &.false {
            background-color: $error-light;
            box-shadow: 0 0 .75rem .75rem $error-light;

            &.disabled {
                background-color: $border-color;
                box-shadow: 0 0 .75rem .75rem $border-color; } } }

    > span.text {
        display: inline-block;
        margin-left: 1rem; } }
