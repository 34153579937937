// maubot - A plugin-based Matrix bot system.
// Copyright (C) 2019 Tulir Asokan
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as published by
// the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

@mixin instancelist() {
    margin: 1rem 0;

    display: flex;
    flex-wrap: wrap;

    > h3 {
        margin: .5rem;
        width: 100%; }

    > a.instance {
        display: block;
        width: calc(50% - 1rem);
        padding: .375rem .5rem;
        margin: .5rem;
        background-color: white;
        border-radius: .25rem;
        color: $text-color;
        text-decoration: none;
        box-sizing: border-box;
        border: 1px solid $primary;

        &:hover {
            background-color: $primary; } } }
