// maubot - A plugin-based Matrix bot system.
// Copyright (C) 2019 Tulir Asokan
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as published by
// the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

> nav.sidebar {
    grid-area: sidebar;
    background-color: white;

    padding: .5rem;

    overflow-y: auto;

    div.buttons {
        margin-bottom: 1.5rem;

        button {
            @include button;
            background-color: white;
            width: 100%; } }

    div.list {
        &:not(:last-of-type) {
            margin-bottom: 1.5rem; }

        div.title {
            h2 {
                display: inline-block;
                margin: 0 0 .25rem 0;
                font-size: 1.25rem; }

            a {
                display: inline-block;
                float: right; } }

        a.entry {
            display: block;
            color: $text-color;
            text-decoration: none;
            padding: .25rem;
            border-radius: .25rem;
            height: 2rem;
            box-sizing: border-box;

            &:not(:hover) > svg.chevron {
                display: none; }

            > svg.chevron {
                float: right; }

            &:hover {
                background-color: $primary-light; }

            &.active {
                background-color: $primary;
                color: white; }

            &.client {
                img.avatar, svg.avatar {
                    max-height: 1.5rem;
                    border-radius: 100%;
                    vertical-align: middle; } }

            span.displayname, span.id {
                margin-left: .25rem;
                vertical-align: middle; } } } }
