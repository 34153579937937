// maubot - A plugin-based Matrix bot system.
// Copyright (C) 2019 Tulir Asokan
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as published by
// the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

@mixin upload-box() {
    position: relative;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    > svg.upload {
        position: absolute;
        display: block;

        padding: 1rem;
        user-select: none; }


    > input.file-selector {
        position: absolute;
        user-select: none;
        opacity: 0; }

    > div.spinner {
        @include thick-spinner; }

    &:not(.uploading) {
        > input.file-selector {
            cursor: pointer; } } }
