$green: #008744;
$blue: #0057e7;
$red: #d62d20;
$yellow: #ffa700;

.spinner {
    position: relative;
    margin: 0 auto;
    width: 5rem;

    &:before {
        content: "";
        display: block;
        padding-top: 100%; }

    svg {
        animation: rotate 2s linear infinite;
        height: 100%;
        transform-origin: center center;
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;

        circle {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
            animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
            stroke-linecap: round; } } }

@mixin white-spinner() {
    circle {
        stroke: white !important; } }

@mixin thick-spinner($thickness: 5) {
    svg > circle {
        stroke-width: $thickness; } }

@keyframes rotate {
    100% {
        transform: rotate(360deg); } }

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0; }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px; }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px; } }

@keyframes color {
    100%, 0% {
        stroke: $red; }
    40% {
        stroke: $blue; }
    66% {
        stroke: $green; }
    80%, 90% {
        stroke: $yellow; } }
