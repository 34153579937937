// maubot - A plugin-based Matrix bot system.
// Copyright (C) 2019 Tulir Asokan
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as published by
// the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

> div.instance {
    > div.preference-table {
        .select-client {
            display: flex;
            align-items: center;

            img.avatar, svg.avatar {
                max-height: 1.375rem;
                border-radius: 50%;
                margin-right: .5rem; } } }

    > div.ace_editor {
        z-index: 0;
        height: 15rem !important;
        width: calc(100% - 1rem) !important;
        font-size: 12px;
        font-family: "Fira Code", monospace;

        margin: .75rem .5rem 1.5rem; } }
